* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}


.services {
  /*background-image: url('/src/components/img-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;*/

    background-color: #fff;
    color: #000;
    font-family: "Open Sans",Arial,sans-serif;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    overflow-x: hidden!important;

    font-family: "PT Sans", Arial, sans-serif !important;
    font-size: 13px !important;
  
}
img{
  position:-webkit-sticky;
  top: 8px;
  right: 16px;
 
}
.heading-primary{
  color: #0088CC !important;
}
.icerik-kapsa{
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px dotted #ddd;
}
.m15{
  margin-bottom: 15px !important;
}
.w100y {
  width: 100% !important;
}
.left {
  float: left !important;
}
li{
  line-height: 24px;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
ul {
  margin-top: 0;
  margin-bottom: 10px;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}


.orta{
  margin-top: 15px;
  background: rgb(172, 20, 20);
}

.orta-sag{
  float: left;
  margin: 15px;
  width: 470px;
  color: rgb(15, 1, 1);
}
.orta-sol{
  float: left;
  width: 450px;
}

.products {
  background-image: url('/src/components/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: repeat;
  color: #fff;
  font-size: 10px;
}

.sign-up {
  background-image: url('/src/components/img-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.bilgilendirme{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;

}
.pb15 {
  padding-bottom: 15px !important;
}
.f16 {
  font-size: 16px !important;
}
.f14 {
  font-size: 14px;
}
.left {
  float: left !important;
}


.w100y {
    width: 100% !important;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.col-xs-12 {
  width: 100%;
}

.list.list-icons {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}
.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 100px) {
}



.row {
  margin-right: -15px;
  margin-left: -15px;
}
div {
  display: block;
}
.mt15 {
  margin-top: 15px !important;
}

.btn-group-vertical>.btn-group:after, .btn-group-vertical>.btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .modal-header:after, .modal-header:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
  display: table;
  content: " ";
}




.tual { 
  width: 960px; 
  margin:0 ; 
  background-color: burlywood; 
  padding: 30px;
 }
.sol_menu { 
  width: 300px; 
  height: 660px; 
  float:left;
   background-color: aqua; 
 }
.sag_icerik { 
  width: 600px; 
  height: 600px; 
  float:left;
   background-color:aqua; 
   padding: 30px;
}

.sag_icerik_ust{
  width: 600px; 
  height: 600px; 
   background-color: #fff;
   width: 100%;
}
.sag_icerik_orta {
   height: 100px;
    background-color: sandybrown;
   }
.sag_icerik_alt { 
  height: 300px;
   background-color: blueviolet;
 }

 .ornek{
   width: 900px;
   margin:auto;
 }
 .saga-kaydır{
   float: right;
   padding: 0 0 10px 10px;
 }